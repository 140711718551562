* {
  box-sizing: border-box;
}

.slab {
  font-family: Abril Fatface, serif;
  font-weight: normal;
}

body {
  color: #3b3b3b;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  font-family: Open Sans, avenir next, avenir, sans-serif;
  font-size: 1rem;
  display: flex;
}

a {
  color: #515151;
  letter-spacing: .01em;
  font-weight: 600;
  line-height: .95;
  text-decoration: underline;
  transition: all .15s;
  display: inline-block;
}

a:hover {
  color: #3b3b3b;
  transform: translateY(-1px);
}

a:active {
  transform: translateY(1px);
}

figure {
  margin: 0;
}

blockquote {
  color: #626262;
  border-left: 3px solid #fbdca5;
  margin: 1rem 0 0;
  padding: .5em 10px;
  line-height: 1.5;
}

figcaption {
  font-size: italic;
  text-align: right;
  max-width: 30em;
  font-size: .875rem;
}

header {
  background-color: #fff;
  width: 100%;
  height: 60px;
}

nav {
  justify-content: space-between;
  align-items: baseline;
  height: 100%;
  display: flex;
}

nav a {
  text-transform: uppercase;
  border: unset;
  padding: 0 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 60px;
  display: inline-block;
}

.logo {
  color: #7e7e7e;
  letter-spacing: .05em;
  text-decoration: none;
}

.nav__links {
  text-transform: uppercase;
  letter-spacing: .05em;
}

.nav__links a {
  color: #7e7e7e;
  padding: 0 .5rem;
  font-size: .875rem;
  text-decoration: none;
}

.nav__links a:hover {
  text-decoration: underline;
}

.nav__links a:active {
  font-weight: 600;
}

main {
  background: url("bg.fe3cc65c.png") 0 0 / cover no-repeat;
  border-radius: 1px;
}

footer {
  background-color: #fff;
  flex: none;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  display: flex;
}

.footer__content {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

footer a {
  text-align: center;
  border: unset;
  width: 50%;
  padding: 2rem;
  display: inline-block;
}

footer img {
  width: 300px;
  max-width: 100%;
}

.footer-spotify {
  width: 50px;
}

.footer-instagram {
  width: 40px;
}

.footer-apple {
  width: 120px;
}

.footer-bandcamp {
  width: 100px;
}

.footer-big-cartel {
  width: 130px;
}

.footer-youtube {
  width: 55px;
}

.page {
  padding: 2rem 1rem 8rem;
}

.page-home__art {
  margin-bottom: 2rem;
}

.page-home__art img {
  width: 100%;
  box-shadow: 0 4px 4px #f1ece2;
}

.page-home__copy {
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1.5rem;
  display: flex;
}

.page-home__copy-date {
  text-align: right;
}

.page-home__copy h1, .page-home__copy-date > p:first-of-type {
  color: #a8a28a;
}

.songkick-attr img {
  width: 100px;
}

.fetch-error {
  line-height: 1.5;
}

.link-button {
  letter-spacing: .06em;
  text-transform: uppercase;
  color: #645c3f;
  background-color: #fbdca5;
  border-radius: 1px;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-weight: 600;
  transition: all .15s;
  display: inline-flex;
  box-shadow: 0 4px 6px #32325d1c, 0 1px 3px #00000014;
}

.link-button:active {
  transform: translateY(1px);
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.link-button:hover {
  transform: translateY(-1px);
}

table {
  table-layout: fixed;
  width: 100%;
  font-size: 1rem;
}

table th {
  text-align: left;
  text-transform: uppercase;
  color: #ada99f;
  letter-spacing: .05em;
}

table th, table td {
  vertical-align: top;
  padding-top: .5rem;
  padding-bottom: 1rem;
}

.table-venue {
  flex-wrap: wrap;
  align-items: baseline;
}

.table-venue a {
  margin-right: .5rem;
}

.support-row {
  color: #3b3b3b;
  margin-top: .5rem;
  font-size: .875rem;
}

.contact-block {
  margin-bottom: 4rem;
}

.contact-block__header {
  text-transform: uppercase;
  color: #a8a28a;
  letter-spacing: .06em;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 600;
}

.contact-block__name {
  color: #515151;
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
}

@media screen and (width >= 700px) {
  main {
    flex: 1;
    width: calc(100% - 2rem);
    height: calc(100% - 120px);
    margin: 0 1rem;
    position: fixed;
    top: 60px;
    overflow-y: auto;
  }

  .nav__links a {
    padding: .25rem;
  }

  header {
    width: 100%;
    height: 60px;
    padding-left: .25rem;
    padding-right: 1rem;
    position: fixed;
  }

  footer {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0;
    position: fixed;
    bottom: 0;
  }

  .footer__content {
    flex-wrap: nowrap;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  footer a {
    width: auto;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding: 1rem;
  }

  footer a:last-child, footer a:nth-last-child(2) {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  footer img, .footer-spotify, .footer-apple, .footer-bandcamp, .footer-youtube, .footer-big-cartel {
    width: 75px;
  }

  .footer-youtube, .footer-instagram {
    width: 24px;
  }

  .footer-bandcamp {
    width: 44px;
  }

  .footer-spotify {
    width: 24px;
  }

  .page {
    max-width: 1000px;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    display: flex;
  }

  .page-home {
    align-items: center;
    height: 100%;
  }

  .page-home__art {
    order: 2;
    max-width: 400px;
    margin-bottom: 0;
  }

  .page-home__inner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .page-home__copy {
    flex-direction: column;
    order: 1;
    align-items: flex-start;
    margin-bottom: .25rem;
  }

  .page-home__copy-date {
    text-align: left;
  }

  .link-button {
    min-width: 190px;
    height: 40px;
    font-size: .875rem;
  }
}

@media screen and (height <= 400px) {
  header {
    position: static;
  }

  main {
    overflow-y: initial;
    height: auto;
    position: static;
  }

  footer {
    position: static;
  }

  .page {
    height: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media screen and (width <= 700px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding: 1rem 0;
  }

  td {
    border: none;
    grid-template-columns: 120px 1fr;
    display: grid;
    position: relative;
  }

  td:before {
    text-align: left;
    text-transform: uppercase;
    color: #ada99f;
    letter-spacing: .05em;
    white-space: nowrap;
    font-weight: 600;
    top: 12px;
    left: 6px;
  }

  td:first-of-type:before {
    content: "Date";
  }

  td:nth-of-type(2):before {
    content: "City";
  }

  td:nth-of-type(3):before {
    content: "Venue";
  }
}

.d-none {
  display: none;
}

.db {
  display: block;
}

.measure {
  max-width: 30em;
}

.flex-1 {
  flex: 1;
}

.w-100 {
  width: 100%;
}

.f-6, .f-headline {
  font-size: 6rem;
}

.f-5, .f-subheadline {
  font-size: 5rem;
}

.fw-normal {
  font-weight: normal;
}

.fw-semibold {
  font-weight: 600;
}

.f1 {
  font-size: 3rem;
}

.f2 {
  font-size: 2.25rem;
}

.f3 {
  font-size: 1.5rem;
}

.f4 {
  font-size: 1.25rem;
}

.f5 {
  font-size: 1rem;
}

.f6 {
  font-size: .875rem;
}

.f7 {
  font-size: .75rem;
}

.pa0 {
  padding: 0;
}

.pa1 {
  padding: .25rem;
}

.pa2 {
  padding: .5rem;
}

.pa3 {
  padding: 1rem;
}

.pa4 {
  padding: 2rem;
}

.pa5 {
  padding: 4rem;
}

.pa6 {
  padding: 8rem;
}

.pa7 {
  padding: 16rem;
}

.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: .25rem;
}

.pl2 {
  padding-left: .5rem;
}

.pl3 {
  padding-left: 1rem;
}

.pl4 {
  padding-left: 2rem;
}

.pl5 {
  padding-left: 4rem;
}

.pl6 {
  padding-left: 8rem;
}

.pl7 {
  padding-left: 16rem;
}

.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: .25rem;
}

.pr2 {
  padding-right: .5rem;
}

.pr3 {
  padding-right: 1rem;
}

.pr4 {
  padding-right: 2rem;
}

.pr5 {
  padding-right: 4rem;
}

.pr6 {
  padding-right: 8rem;
}

.pr7 {
  padding-right: 16rem;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: .25rem;
}

.pb2 {
  padding-bottom: .5rem;
}

.pb3 {
  padding-bottom: 1rem;
}

.pb4 {
  padding-bottom: 2rem;
}

.pb5 {
  padding-bottom: 4rem;
}

.pb6 {
  padding-bottom: 8rem;
}

.pb7 {
  padding-bottom: 16rem;
}

.pt0 {
  padding-top: 0;
}

.pt1 {
  padding-top: .25rem;
}

.pt2 {
  padding-top: .5rem;
}

.pt3 {
  padding-top: 1rem;
}

.pt4 {
  padding-top: 2rem;
}

.pt5 {
  padding-top: 4rem;
}

.pt6 {
  padding-top: 8rem;
}

.pt7 {
  padding-top: 16rem;
}

.pv0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pv1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pv2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pv4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pv5 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pv6 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.pv7 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.ph0 {
  padding-left: 0;
  padding-right: 0;
}

.ph1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.ph2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ph5 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.ph6 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.ph7 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.ma0 {
  margin: 0;
}

.ma1 {
  margin: .25rem;
}

.ma2 {
  margin: .5rem;
}

.ma3 {
  margin: 1rem;
}

.ma4 {
  margin: 2rem;
}

.ma5 {
  margin: 4rem;
}

.ma6 {
  margin: 8rem;
}

.ma7 {
  margin: 16rem;
}

.ml0 {
  margin-left: 0;
}

.ml1 {
  margin-left: .25rem;
}

.ml2 {
  margin-left: .5rem;
}

.ml3 {
  margin-left: 1rem;
}

.ml4 {
  margin-left: 2rem;
}

.ml5 {
  margin-left: 4rem;
}

.ml6 {
  margin-left: 8rem;
}

.ml7 {
  margin-left: 16rem;
}

.mr0 {
  margin-right: 0;
}

.mr1 {
  margin-right: .25rem;
}

.mr2 {
  margin-right: .5rem;
}

.mr3 {
  margin-right: 1rem;
}

.mr4 {
  margin-right: 2rem;
}

.mr5 {
  margin-right: 4rem;
}

.mr6 {
  margin-right: 8rem;
}

.mr7 {
  margin-right: 16rem;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: .25rem;
}

.mb2 {
  margin-bottom: .5rem;
}

.mb3 {
  margin-bottom: 1rem;
}

.mb4 {
  margin-bottom: 2rem;
}

.mb5 {
  margin-bottom: 4rem;
}

.mb6 {
  margin-bottom: 8rem;
}

.mb7 {
  margin-bottom: 16rem;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: .25rem;
}

.mt2 {
  margin-top: .5rem;
}

.mt3 {
  margin-top: 1rem;
}

.mt4 {
  margin-top: 2rem;
}

.mt5 {
  margin-top: 4rem;
}

.mt6 {
  margin-top: 8rem;
}

.mt7 {
  margin-top: 16rem;
}

.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mv1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mv2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mv3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mv4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mv5 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mv6 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mv7 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mh0 {
  margin-left: 0;
  margin-right: 0;
}

.mh1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mh2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mh3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mh4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mh5 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mh6 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mh7 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.lh-solid {
  line-height: 1;
}

.lh-title {
  line-height: 1.25;
}

.lh-copy {
  line-height: 1.5;
}

.grey-yellow {
  color: #a8a28a;
}

.grey-100 {
  color: #e1e1e1;
}

.grey-200 {
  color: #cfcfcf;
}

.grey-300 {
  color: #b1b1b1;
}

.grey-400 {
  color: #9e9e9e;
}

.grey-500 {
  color: #7e7e7e;
}

.grey-600 {
  color: #626262;
}

.grey-700 {
  color: #515151;
}

.grey-800 {
  color: #3b3b3b;
}

.grey-900 {
  color: #222;
}

.tracked {
  letter-spacing: .06em;
}

.ttu {
  text-transform: uppercase;
}

@media screen and (width >= 700px) {
  .measure-ns {
    max-width: 30em;
  }

  .pa0-ns {
    padding: 0;
  }

  .pa1-ns {
    padding: .25rem;
  }

  .pa2-ns {
    padding: .5rem;
  }

  .pa3-ns {
    padding: 1rem;
  }

  .pa4-ns {
    padding: 2rem;
  }

  .pa5-ns {
    padding: 4rem;
  }

  .pa6-ns {
    padding: 8rem;
  }

  .pa7-ns {
    padding: 16rem;
  }

  .pl0-ns {
    padding-left: 0;
  }

  .pl1-ns {
    padding-left: .25rem;
  }

  .pl2-ns {
    padding-left: .5rem;
  }

  .pl3-ns {
    padding-left: 1rem;
  }

  .pl4-ns {
    padding-left: 2rem;
  }

  .pl5-ns {
    padding-left: 4rem;
  }

  .pl6-ns {
    padding-left: 8rem;
  }

  .pl7-ns {
    padding-left: 16rem;
  }

  .pr0-ns {
    padding-right: 0;
  }

  .pr1-ns {
    padding-right: .25rem;
  }

  .pr2-ns {
    padding-right: .5rem;
  }

  .pr3-ns {
    padding-right: 1rem;
  }

  .pr4-ns {
    padding-right: 2rem;
  }

  .pr5-ns {
    padding-right: 4rem;
  }

  .pr6-ns {
    padding-right: 8rem;
  }

  .pr7-ns {
    padding-right: 16rem;
  }

  .pb0-ns {
    padding-bottom: 0;
  }

  .pb1-ns {
    padding-bottom: .25rem;
  }

  .pb2-ns {
    padding-bottom: .5rem;
  }

  .pb3-ns {
    padding-bottom: 1rem;
  }

  .pb4-ns {
    padding-bottom: 2rem;
  }

  .pb5-ns {
    padding-bottom: 4rem;
  }

  .pb6-ns {
    padding-bottom: 8rem;
  }

  .pb7-ns {
    padding-bottom: 16rem;
  }

  .pt0-ns {
    padding-top: 0;
  }

  .pt1-ns {
    padding-top: .25rem;
  }

  .pt2-ns {
    padding-top: .5rem;
  }

  .pt3-ns {
    padding-top: 1rem;
  }

  .pt4-ns {
    padding-top: 2rem;
  }

  .pt5-ns {
    padding-top: 4rem;
  }

  .pt6-ns {
    padding-top: 8rem;
  }

  .pt7-ns {
    padding-top: 16rem;
  }

  .pv0-ns {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pv1-ns {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .pv2-ns {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .pv3-ns {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pv4-ns {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pv5-ns {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .pv6-ns {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .pv7-ns {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ph0-ns {
    padding-left: 0;
    padding-right: 0;
  }

  .ph1-ns {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .ph2-ns {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .ph3-ns {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ph4-ns {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .ph5-ns {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ph6-ns {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .ph7-ns {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .ma0-ns {
    margin: 0;
  }

  .ma1-ns {
    margin: .25rem;
  }

  .ma2-ns {
    margin: .5rem;
  }

  .ma3-ns {
    margin: 1rem;
  }

  .ma4-ns {
    margin: 2rem;
  }

  .ma5-ns {
    margin: 4rem;
  }

  .ma6-ns {
    margin: 8rem;
  }

  .ma7-ns {
    margin: 16rem;
  }

  .ml0-ns {
    margin-left: 0;
  }

  .ml1-ns {
    margin-left: .25rem;
  }

  .ml2-ns {
    margin-left: .5rem;
  }

  .ml3-ns {
    margin-left: 1rem;
  }

  .ml4-ns {
    margin-left: 2rem;
  }

  .ml5-ns {
    margin-left: 4rem;
  }

  .ml6-ns {
    margin-left: 8rem;
  }

  .ml7-ns {
    margin-left: 16rem;
  }

  .mr0-ns {
    margin-right: 0;
  }

  .mr1-ns {
    margin-right: .25rem;
  }

  .mr2-ns {
    margin-right: .5rem;
  }

  .mr3-ns {
    margin-right: 1rem;
  }

  .mr4-ns {
    margin-right: 2rem;
  }

  .mr5-ns {
    margin-right: 4rem;
  }

  .mr6-ns {
    margin-right: 8rem;
  }

  .mr7-ns {
    margin-right: 16rem;
  }

  .mb0-ns {
    margin-bottom: 0;
  }

  .mb1-ns {
    margin-bottom: .25rem;
  }

  .mb2-ns {
    margin-bottom: .5rem;
  }

  .mb3-ns {
    margin-bottom: 1rem;
  }

  .mb4-ns {
    margin-bottom: 2rem;
  }

  .mb5-ns {
    margin-bottom: 4rem;
  }

  .mb6-ns {
    margin-bottom: 8rem;
  }

  .mb7-ns {
    margin-bottom: 16rem;
  }

  .mt0-ns {
    margin-top: 0;
  }

  .mt1-ns {
    margin-top: .25rem;
  }

  .mt2-ns {
    margin-top: .5rem;
  }

  .mt3-ns {
    margin-top: 1rem;
  }

  .mt4-ns {
    margin-top: 2rem;
  }

  .mt5-ns {
    margin-top: 4rem;
  }

  .mt6-ns {
    margin-top: 8rem;
  }

  .mt7-ns {
    margin-top: 16rem;
  }

  .mv0-ns {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mv1-ns {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .mv2-ns {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .mv3-ns {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mv4-ns {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mv5-ns {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .mv6-ns {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .mv7-ns {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .mh0-ns {
    margin-left: 0;
    margin-right: 0;
  }

  .mh1-ns {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .mh2-ns {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .mh3-ns {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mh4-ns {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .mh5-ns {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .mh6-ns {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .mh7-ns {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .f-6-ns, .f-headline-ns {
    font-size: 6rem;
  }

  .f-5-ns, .f-subheadline-ns {
    font-size: 5rem;
  }

  .f1-ns {
    font-size: 3rem;
  }

  .f2-ns {
    font-size: 2.25rem;
  }

  .f3-ns {
    font-size: 1.5rem;
  }

  .f4-ns {
    font-size: 1.25rem;
  }

  .f5-ns {
    font-size: 1rem;
  }

  .f6-ns {
    font-size: .875rem;
  }

  .f7-ns {
    font-size: .75rem;
  }

  .lh-solid-ns {
    line-height: 1;
  }

  .lh-title-ns {
    line-height: 1.25;
  }

  .lh-copy-ns {
    line-height: 1.5;
  }

  .w-auto-ns {
    width: auto;
  }

  .db-ns {
    display: block;
  }

  .d-none-ns {
    display: none;
  }
}
/*# sourceMappingURL=index.749a6988.css.map */
